import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";

import Layout from "../components/Layout";

const ThankYouPTPage = () => {
    return (
        <Layout pageName="confirmation">
            <Helmet>
                <title>Thank You!</title>
            </Helmet>
            <Container align="justify" maxWidth="md">
                <StaticImage src="../assets/images/grapes-1952035_1920.jpg" />
                <Fade style={{ margin: 20 }} in={true}>
                    <MuiAlert variant="outlined" severity="success">
                        Muchas gracias! Your payment was successful
                    </MuiAlert>
                </Fade>
                <Box>
                    <Typography variant="body1" component="div">
                        <br />
                        We are so excited to host you in Baja soon. Thanks for
                        choosing Valle Guadalupe Baja. We are committed to the
                        guest experience. All of our tour guides and drivers are
                        fun, professional and bilingual. Please look for an
                        email soon with your trip itinerary, host information
                        and meeting point details. Get ready for the best time
                        in our favorite place! Gracias y Salud!
                        <br />
                        <br />
                        <ul>
                            <li>
                                <a href="tel:619-361-8571"> 619-361-8571 </a>
                            </li>
                            <li>
                                <a href="mailto:aimee@valleguadalupebaja.com">
                                    aimee@valleguadalupebaja.com
                                </a>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default ThankYouPTPage;
